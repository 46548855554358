.popup_fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  display: none;
}
@media (min-width: 768px) {
  .modal-content .login-form .verifregister a {
    font-size: inherit !important;
  }
  .modal-content .login-form a.clbtn {
    font-size: 24px !important;
  }
  .modal-content .login-form .form-group {
    margin: 10px 0 !important;
  }
  .modal-content .login-form .form_btn {
    margin: 0 !important;
  }
}
.wrapper.popup-displayed {
  height: auto;
  overflow: hidden;
}
@media (min-width: 768px) {
  .wrapper.popup-displayed.landing_page {
    width: 500px;
  }
}
.wrapper.popup-displayed.land {
  z-index: 99999;
}
.body.popup-displayed {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
}
@media (min-width: 768px) {
  .body.popup-displayed {
    width: 500px;
  }
}
.footer.popup-displayed {
  display: none;
}
.section-popup-message {
  display: none;
  position: fixed;
  z-index: 11111;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.59);
}
.section-popup-message .popup-block-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.section-popup-message .popup_input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 10px 6px;
  margin-bottom: 10px;
  outline: none;
}
.section-popup-message .popup_button {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 10px 6px;
  margin-bottom: 10px;
  outline: none;
  margin-top: 10px;
  background-color: #e30611;
  font-size: 18px;
  color: #fff;
  border-radius: 10px;
  padding: 12px 10px;
  text-align: center;
  display: inline-block;
}
.section-popup-message .popup_button:focus,
.section-popup-message .popup_button:active,
.section-popup-message .popup_button:hover {
  text-decoration: none;
}
.section-popup-message .popup_button:disabled {
  background-color: #e35a68;
}
.section-popup-message .popup_submit {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 10px 6px;
  margin-bottom: 10px;
  outline: none;
  margin-top: 10px;
  background-color: #e30611;
  font-size: 18px;
  color: #fff;
  border-radius: 10px;
  padding: 12px 10px;
  text-align: center;
  display: inline-block;
}
.section-popup-message .popup_submit:focus,
.section-popup-message .popup_submit:active,
.section-popup-message .popup_submit:hover {
  text-decoration: none;
}
.section-popup-message .popup_submit:disabled {
  background-color: #e35a68;
}
.section-popup-message .popup-block {
  position: relative;
}
.section-popup-message .popup-block .wrap-popup {
  background-color: #fff;
  border-radius: 10px;
  width: 300px;
  margin: 60px auto;
  padding: 15px;
  position: absolute;
  right: 0;
  left: 0;
}
.section-popup-message .popup-block .wrap-popup .closed {
  height: 30px;
  width: 30px;
  position: absolute;
  top: -35px;
  right: -12px;
  background-size: 68%;
  background-repeat: no-repeat;
  background-image: url("/images/ic_close_white@2x.png");
  -webkit-transition: all .3s;
  transition: all .3s;
}
.section-popup-message .popup-block .wrap-popup .closed:hover {
  opacity: .7;
  cursor: pointer;
}
.section-popup-message .popup-block .wrap-popup .title-popup h3 {
  font-size: 24px;
  text-align: center;
  color: gray;
  margin: 10px 0 20px;
}
.section-popup-message .popup-block .wrap-popup form input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 10px 6px;
  margin-bottom: 10px;
  outline: none;
}
.section-popup-message .popup-block .wrap-popup form textarea {
  width: 100%;
  border: 1px solid #ccc;
  padding: 0 6px;
  margin-bottom: 10px;
  outline: none;
}
.section-popup-message .popup-block .wrap-popup form input[type="submit"] {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 10px 6px;
  margin-bottom: 10px;
  outline: none;
  margin-top: 10px;
  background-color: #e30611;
  font-size: 18px;
  color: #fff;
  border-radius: 10px;
  padding: 12px 10px;
  text-align: center;
  display: inline-block;
}
.section-popup-message .popup-block .wrap-popup form input[type="submit"]:focus,
.section-popup-message .popup-block .wrap-popup form input[type="submit"]:active,
.section-popup-message .popup-block .wrap-popup form input[type="submit"]:hover {
  text-decoration: none;
}
.section-popup-message .popup-block .wrap-popup form input[type="submit"]:disabled {
  background-color: #e35a68;
}
.section-popup-message .popup-block .wrap-popup form .remember {
  text-align: center;
}
.section-popup-message .popup-block .wrap-popup form .remember a {
  color: #808080;
  margin: auto;
  border-bottom: 1px solid;
}
.section-popup-message .popup-block .wrap-popup form p.number {
  margin-top: -5px;
  font-size: 14px;
  color: #808080;
}
.section-popup-message .popup-block .wrap-popup form p.desc {
  text-align: center;
  color: #b3b3b3;
  font-size: 12px;
  margin-top: 20px;
}
.section-popup-message.error-room p.desc,
.section-popup-message.error-site p.desc {
  text-align: center;
}
